import { endpoints } from '@source/config'
const imgBaseUrl = `${endpoints.asset}/images`
const cssBaseUrl = `${endpoints.asset}/css`
const pdfBaseUrl = `${endpoints.asset}/pdf`

const search = `${imgBaseUrl}/search`
export const errorImg = `${search}/error.png`

export const infografikEdukasiPdf = `${pdfBaseUrl}/infografik_edukasi_hak_cipta_v04.pdf`
export const brosurHaki = `${pdfBaseUrl}/brosur_haki.pdf`
export const brosurHakiV2 = `${pdfBaseUrl}/brosur_haki_v2.pdf`

const radio = `${imgBaseUrl}/radio`
export const unactiveRadioBtn = `${radio}/unactive.png`
export const activeRadioBtn = `${radio}/active.png`

const cb = `${imgBaseUrl}/checkbox`
export const unactiveCbBtn = `${cb}/unactive.png`
export const activeCbBtn = `${cb}/active.png`

export const semantic = `${cssBaseUrl}/semantic-ui@2.4.2.min.css`

const eye = `${imgBaseUrl}/eye`
export const eyeVisible = `${eye}/visible.png`
export const eyeNotVisible = `${eye}/notvisible.png`

const error = `${imgBaseUrl}/error`
export const lightMode404 = `${error}/light_404.png`
export const darkMode404 = `${error}/dark_404.png`
export const darkMode505 = `${error}/dark_502.png`
export const commonError = `${error}/error.png`

const common = `${imgBaseUrl}/common`
export const favicon = `${common}/favicon.png`
export const logoMLA = `${common}/logoMLALight.png`
export const placeHolderIcon = `${common}/placeholder.svg`
export const molaLogo = `${common}/mola.png`
export const twitterIcon = `${common}/twitter_icon.png`
export const facebookIcon = `${common}/facebook_icon.png`
export const instagramIcon = `${common}/instagram_icon.png`

const home = `${imgBaseUrl}/home`
export const backgroundHome = `${home}/home_background_v1.jpg`

export const imgPopup1 = `${home}/popup/popup1_v2.jpg`
export const imgPopup2 = `${home}/popup/popup2_v2.jpg`
// export const imgPopup3 = `${home}/popup/popup3.jpg`

const about = `${imgBaseUrl}/about`
export const backgroundAbout = `${about}/brief_v1.jpg`
export const backgroundAboutVersionTwo = `${about}/brief_v2.jpg`
export const backgroundAboutVersionThree = `${about}/brief_v3.jpg`
export const accommodationRegulerIcon = `${about}/licence-icons/accommodation_reguler_icon.svg`
export const accommodationPremiumIcon = `${about}/licence-icons/accommodation_premium_icon.svg`
export const largeCommercialIcon = `${about}/licence-icons/large_commercial_icon.svg`
export const smallCommercialIcon = `${about}/licence-icons/small_commercial_icon.svg`
export const matrixImage = `${about}/device-images/matrix.png`
export const mpsImage = `${about}/device-images/mps.png`
export const smartPolytronTV = `${about}/device-images/smart_tv.png`
export const mpsImageV2 = `${about}/device-images/mps_2.png`
export const smartPolytronTVV2 = `${about}/device-images/smart_tv_2.png`
export const mpsImageV3 = `${about}/device-images/mps_3.png`
export const smartPolytronTVV3 = `${about}/device-images/smart_tv_3.png`
export const tokopediaLogo = `${about}/marketplace-logos/tokopedia.png`
export const blibliLogo = `${about}/marketplace-logos/blibli.png`

const semanticFonts = `${cssBaseUrl}/themes/default/assets/fonts`
export const semanticIcons = `${semanticFonts}/icons`
export const semanticOutlineIcons = `${semanticFonts}/outline-icons`
export const semanticBrandIcons = `${semanticFonts}/brand-icons`
