export const setCookie = (name, value, days) => {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const getCookie = name => {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length == 2)
    return parts
      .pop()
      .split(';')
      .shift()
}

export  const isTrue = obj => {
  return Object.keys(obj).filter(o => obj[o])
}

export  const lowerUnderscored = str => {
  return str.replace(/\s/g, '_').toLowerCase()
}

export  const stopPropagationElement = element => {
  element.stopPropagation()
}
