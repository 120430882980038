import React, { useState } from 'react';
import {
  Container,
  WrapHeader,
  Header as HeaderStyled,
  PrimarySection,
  HamburgerMenu,
  Footer as FooterStyled,
  HeaderLogo,
  DesktopMenu,
} from '../styles';
import Drawer from './Drawer';
import Menus from './Menu';

import { logoMLA } from '@source/global/imageUrl';
import theme from '../../../../components/Layout/theme';

const Header = ({ layout = "fixed", pathname, onClickHamburger, }) => {
  const lightTheme = layout === 'fixed'
  return (
    <HeaderStyled theme={lightTheme && 'light'}>
      <WrapHeader layout={layout}>
        <PrimarySection>
          <HamburgerMenu
            color={lightTheme ? 'dark' : 'light'}
            onClick={() => onClickHamburger()}
          />
          {layout === 'fixed' && (
            <HeaderLogo href="/beranda">
              <img src={logoMLA} alt="molalivearena logo" />
            </HeaderLogo>
          )}
          <DesktopMenu>
            <Menus theme={lightTheme && 'light'} pathname={pathname} />
          </DesktopMenu>
        </PrimarySection>
      </WrapHeader>
    </HeaderStyled>
  )
}

const Footer = ({ theme, bg }) => (
  <FooterStyled bg={bg} theme={theme}>
    <p>Mola Live © {new Date().getFullYear()} - Hak cipta, siar, dan publikasi Mola Live telah dilindungi oleh undang-undang.</p>
  </FooterStyled>
)

const LayoutPublic = ({
  children,
  pathname,
  layout = 'fixed',
  footerTheme = 'light',
  bgFooter,
  isMobile = false,
}) => {
  const [sidenav, setSidenav] = useState(false)
  const footerBackground = bgFooter === 'transparent' ?
    bgFooter : bgFooter === 'dark' ?
      theme.colors.blueDarker : bgFooter ? bgFooter : theme.colors.white
  return (
    <Container>
      <Header layout={layout} pathname={pathname} onClickHamburger={() => setSidenav(true)} />
      {children}
      {!isMobile && <Footer theme={footerTheme} bg={footerBackground} />}
      <Drawer pathname={pathname} open={sidenav} onClose={() => setSidenav(false)} />
    </Container>
  )
}

export {
  LayoutPublic as default,
  Footer
}
