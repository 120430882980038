let env = 'staging'
if (__BROWSER__) {
  env = window.App.env || 'production'
} else {
  env = process.env.REACT_APP_ENV
}

const configEnv = {
  development: {
    port: 3000,
    appId: 'molalivearena',
    configUrl: 'http://10.225.132.61',
    endpoints: {
      clientUrl: '',
      serverUrl: 'https://stag.molalivearena.com',
      // api: 'https://stag.molalivearena.com/api/v2',
      api: '/api/v2',
      auth: 'https://stag.molalivearena.com/accounts/_',
      domain: 'https://stag.molalivearena.com',
      asset: 'https://cdn.stag.supersoccer.tv/mla01/assets-global',
      setting: {
        timeout: 10000,
        maxRedirects: 1
      },
      mola :'https://stag.mola.tv'
    }
  },
  staging: {
    port: 3000,
    appId: 'molalivearena',
    configUrl: 'http://config.core.svc.cluster.local',
    endpoints: {
      clientUrl: '',
      serverUrl: 'https://stag.molalivearena.com',
      api: 'https://stag.molalivearena.com/api/v2',
      // api: '/api/v2',
      auth: 'https://stag.molalivearena.com/accounts/_',
      domain: 'https://stag.molalivearena.com',
      asset: 'https://cdn.stag.supersoccer.tv/mla01/assets-global',
      setting: {
        timeout: 10000,
        maxRedirects: 1
      },
      mola :'https://stag.mola.tv'
    }
  },
  production: {
    port: 3000,
    appId: 'molalivearena',
    configUrl: 'http://config.core.sstv.local',
    endpoints: {
      clientUrl: '',
      serverUrl: 'https://molalivearena.com',
      api: 'https://molalivearena.com/api/v2',
      auth: 'https://molalivearena.com/accounts/_',
      domain: 'https://molalivearena.com',
      asset: 'https://mla01.koicdn.com/assets-global',
      setting: {
        timeout: 10000,
        maxRedirects: 1
      },
      mola :'https://mola.tv'
    }
  }
}

const oauth = {
  endpoint: `${process.env.DOMAIN}/accounts/_/oauth2/v1`,
  localEndpoint: process.env.ACCOUNT_NODE_API_URL,
  appKey: process.env.APP_KEY,
  appSecret: process.env.APP_SECRET,
  scope: [
    'molanobar:products.read',
    'molanobar:orders.create',
    'molanobar:orders.update',
    'molanobar:orders.delete',
    'molanobar:orders.read',
    'molanobar:venues.read',
    'molanobar:venues.create',
    'molanobar:venues.update',
    'molanobar:venues.delete',
    'molanobar:installations.read',
    'molanobar:devices.read',
    'molanobar:commercial_types.read',
    'molanobar:rooms.read',
    'molanobar:venue_types.read',
    'molanobar:agings.read',
    'molanobar:licenses.read',
    'molanobar:admins.read',
    'molanobar:agents.read',
    'molanobar:companies.read',
    'molanobar:companies.create',
    'molanobar:companies.update',
    'molanobar:companies.delete',
    'molanobar:email.ecert',
    'molanobar:order_matrices.read',
  ].join(' ')
};

const mapsConfig = {
  API_KEY: process.env.REACT_APP_MAP_API_URL || 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDgp3oE0TWechzZ6BCU7yseNXNEkNnNDpU&v=3&libraries=geometry,drawing,places',
};

const appId = process.env.APP_ID || 'molalivearena'

const serverApi = {
  USER_API_URL: '/api/v2/molanobar/sumorders',
  ADMINS_API_URL: '/api/v2/molanobar/admins-check',
  appId,
};

const upload = {
  url: process.env.REACT_APP_MLA_UPLOAD_URL,
  id: process.env.REACT_APP_MLA_UPLOAD_ID,
  secret: process.env.REACT_APP_MLA_UPLOAD_SECRET,
  token: process.env.REACT_APP_MLA_UPLOAD_TOKEN,
}
const molaPartnerIframe = process.env.REACT_APP_MOLA_PARTNER_IFRAME || 'xif19419267'
const config = configEnv[env];

module.exports = {
  env,
  serverApi,
  mapsConfig,
  oauth,
  upload,
  appId,
  molaPartnerIframe,
  port: process.env.PORT || 3000,
  ...config,
};
