/* eslint-disable import/prefer-default-export */
import { endpoints } from '@source/config';
export const VENUES_ENDPOINT = `${endpoints.api}/molanobar`;
export const VENUE_ENDPOINT = `${endpoints.api}/molanobar/venues`;
export const ORDERS_BY_UID_ENDPOINT = `${
  endpoints.api
}/molanobar/orders-by-buyerid/`;
export const DEVICES_ENDPOINT = `${endpoints.api}/molanobar/devices`;
export const PRODUCTS_ENDPOINT = `${endpoints.api}/molanobar/products`;
export const AGING_ENDPOINT = `${endpoints.api}/molanobar/aging`;
export const INSTALLATION_ENDPOINT = `${endpoints.api}/molanobar/installation`;
export const ORDERS_ENDPOINT = `${endpoints.api}/molanobar/orders`;
export const SUM_ORDERS_ENDPOINT = `${endpoints.api}/molanobar/sumorders`;
export const POST_DO_PAYMENT_ENDPOINT = `${
  endpoints.api
}/molanobar/orders-do-payment`;
export const GET_COMPANIES_ENDPOINT = `${endpoints.api}/molanobar/companies`;
export const GET_LICENSE_CHECKER_ENDPOINT = `${
  endpoints.api
}/molanobar/licensechecker`;
export const POST_SEND_CERTIFICATE_ENDPOINT = `${
  endpoints.api
}/molanobar/sendmailecert`;
export const GET_IS_ADMIN_ENDPOINT = `${endpoints.api}/molanobar/admin`;
export const POST_SHOW_STATUS_ENDPOINT = `${
  endpoints.api
}/molanobar/venues/show`;
