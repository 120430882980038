import Search from '@api/common';
import types from '../constants'
import searchDb from '../database/searchDb'
import Dexie from 'dexie'

const getSearchResult = searchText => dispatch => {
  dispatch({
    type: types.GET_SEARCH_LOADING,
    payload: {
      meta: {
        status: 'loading',
        error: '',
      },
      data: [],
    },
  })
  var isExist = false
  var cacheResult = {
    meta: {
      status: 'success',
    },
    data: [],
  }
  if (navigator.cookieEnabled) {
    Dexie.exists('mla-search-cache-database').then(function(exists) {
      if (exists) {
        searchDb
          .transaction('r', searchDb.venuesResult, searchDb.citiesResult, searchDb.searchKeyword, searchDb.recentResult, async () => {
            //check on cache IndexedDB if keyword and search result exists
            await searchDb.searchKeyword
              .where('keyword')
              .equalsIgnoreCase(searchText)
              .each(function(res) {
                isExist = true //if exist
                if (res.venueId) {
                  const venueIdArr = res.venueId.split(',')
                  venueIdArr.map(id => {
                    searchDb.venuesResult
                      .where('venueId')
                      .equals(parseInt(id))
                      .each(function(res) {
                        cacheResult.data.push({
                          id: res.id,
                          type: res.type,
                          key: res.key,
                        })
                      })
                  })
                }
                if (res.cityId) {
                  const cityArr = res.cityId.split(',')
                  cityArr.map(id => {
                    searchDb.citiesResult
                      .where('cityId')
                      .equals(parseInt(id))
                      .each(function(res) {
                        cacheResult.data.push({
                          id: res.id,
                          type: res.type,
                          key: res.key,
                        })
                      })
                  })
                }
              })
          })
          .then(() => {
            if (!isExist) {
              //if keyword not exist in cache indexed DB then get from api and store in cache
              return Search.getSearchResult({ q: searchText }).then(result => {
                if (result.meta.status === 'error') {
                  dispatch({
                    type: types.GET_SEARCH_FAILED,
                    payload: result,
                  })
                } else {
                  dispatch({
                    type: types.GET_SEARCH_SUCCESS,
                    payload: result,
                  })

                  var curDate = new Date()

                  var createdDate =
                    curDate.getFullYear() +
                    '-' +
                    ('0' + (curDate.getMonth() + 1)).slice(-2) +
                    '-' +
                    ('0' + curDate.getDate()).slice(-2) +
                    ' 00:00:00'

                  var venueIdAdded = []
                  var cityAdded = []
                  searchDb.transaction(
                    'rw',
                    searchDb.venuesResult,
                    searchDb.citiesResult,
                    searchDb.searchKeyword,
                    async () => {
                      //loop result from api to store to cache db
                      for (const dt of result.data) {
                        var isVenueIdExist = false
                        var isCityExist = false

                        //check type to store to related table
                        if (dt.type == 'venue_name') {
                          //check if movieid already exist in cache db so prevent duplication
                          await searchDb.venuesResult
                            .where('venueId')
                            .equals(dt.id)
                            .count(function(cnt) {
                              isVenueIdExist = cnt > 0
                            })
                            .then(() => {
                              if (!isVenueIdExist) {
                                //if not exist then store
                                searchDb.venuesResult.add({
                                  venueId: dt.id,
                                  type: dt.type,
                                  key: dt.key,
                                  createdDate: createdDate,
                                })
                              }
                            })
                          venueIdAdded.push(dt.id)
                        } else {
                          //check if city already exist in cache db so prevent duplication
                          await searchDb.citiesResult
                            .where('cityId')
                            .equals(dt.id)
                            .count(function(cnt) {
                              isCityExist = cnt > 0
                            })
                            .then(() => {
                              if (!isCityExist) {
                                //if not exist then store
                                searchDb.citiesResult.add({
                                  type: dt.type,
                                  cityId: dt.id,
                                  key: dt.key,
                                  createdDate: createdDate,
                                })
                              }
                            })
                          cityAdded.push(dt.id)
                        }
                      }
                      searchDb.searchKeyword.add({
                        keyword: searchText,
                        venueId: venueIdAdded.join(','),
                        cityId: cityAdded.join(','),
                        createdDate: createdDate,
                      })
                    }
                  )
                }
              })
            } else {
              if (cacheResult.data.length > 0) {
                dispatch({
                  type: types.GET_SEARCH_SUCCESS,
                  payload: cacheResult,
                })
              } else {
                dispatch({
                  type: types.GET_SEARCH_FAILED,
                  payload: cacheResult,
                })
              }
            }
          })
      } else {
        return Search.getSearchResult({ q: searchText }).then(result => {
          if (result.meta.status === 'error') {
            dispatch({
              type: types.GET_SEARCH_FAILED,
              payload: result,
            })
          } else {
            dispatch({
              type: types.GET_SEARCH_SUCCESS,
              payload: result,
            })
          }
        })
      }
    })
  } else {
    return Search.getSearchResult({ q: searchText }).then(result => {
      if (result.meta.status === 'error') {
        dispatch({
          type: types.GET_SEARCH_FAILED,
          payload: result,
        })
      } else {
        dispatch({
          type: types.GET_SEARCH_SUCCESS,
          payload: result,
        })
      }
    })
  }
}

const getSearchSuggestion = text => dispatch => {
  dispatch({
    type: types.GET_SEARCH_LOADING,
    payload: {
      meta: {
        status: 'loading',
        error: '',
      },
      data: [],
    },
  })
  Search.getSearchResult({ q: text }).then(result => {
    if (result.meta.status === 'error') {
      dispatch({
        type: types.GET_SEARCH_FAILED,
        payload: result,
      })
    } else {
      dispatch({
        type: types.GET_SEARCH_SUCCESS,
        payload: result,
      })
    }
  })
}

export default {
  getSearchSuggestion,
  getSearchResult
}