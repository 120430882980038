import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import './fonts';
// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import styles from './Layout.css';
import Loading from '../loadingHandler';
import ReduxToastr from 'react-redux-toastr';
class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };
  componentDidMount() {
    document.querySelector(':root').style
      .setProperty('--fullvh', window.innerHeight  + 'px');
    window.addEventListener('resize', () => {
      document.querySelector(':root').style
        .setProperty('--fullvh', window.innerHeight  + 'px');
    })
  }

  render() {
    return (
      <>
        <Loading />
        <ReduxToastr
          timeOut={8000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        {this.props.children}
      </>
    );
  }
}

export default withStyles(normalizeCss, styles)(Layout);
