import { get, post, delete as axiosDelete, patch } from 'axios';
import _get from 'lodash/get';
import {
  VENUES_ENDPOINT,
  SUM_ORDERS_ENDPOINT,
  POST_DO_PAYMENT_ENDPOINT,
  GET_COMPANIES_ENDPOINT,
  GET_LICENSE_CHECKER_ENDPOINT,
  POST_SEND_CERTIFICATE_ENDPOINT,
  GET_IS_ADMIN_ENDPOINT,
  POST_SHOW_STATUS_ENDPOINT,
  VENUE_ENDPOINT
} from './endpoints';
import utils from './util';
import history from '@source/history';
import qs from 'query-string';

import { endpoints } from '@source/config';
import config from '@source/config';
// eslint-disable-next-line no-unused-vars
import { AUTH_BASE_ENDPOINT } from '../auth/endpoints';
// eslint-disable-next-line no-unused-vars
const { serverApi: { USER_API_URL, appId } } = config;

const getVenues = token => {
  return get(`${VENUES_ENDPOINT}/order-matrix-venue-types?app_id=${appId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normailzeVenueList(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getVenues', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getCapacities = (token, param) => {
  return get(
    `${VENUES_ENDPOINT}/order-matrix-capacities/${param}?app_id=${appId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...endpoints.setting
    }
  )
    .then(response => {
      const result = utils.normailzeCapacities(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getCapacities', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getDataCompanies = token => {
  return get(`${GET_COMPANIES_ENDPOINT}?app_id=${appId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeCompanies(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getDataCompanies', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getDataCompaniesById = (id, token) => {
  return get(`${GET_COMPANIES_ENDPOINT}/${id}?app_id=${appId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const tempDataMap = {
        data: {
          data: [
            {
              attributes: response.data.data.attributes
            }
          ]
        }
      };
      const result = utils.normalizeCompanies(tempDataMap);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getDataCompaniesById', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const postCompanies = (token, data) => {
  return post(`${GET_COMPANIES_ENDPOINT}?app_id=${appId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    withCredentials: true,
    ...endpoints.setting
  })
    .then(response => {
      const tempDataMap = {
        data: {
          data: [
            {
              attributes: response.data.data
            }
          ]
        }
      };
      const result = utils.normalizeCompanies(tempDataMap);
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: result
      };
    })
    .catch(error => {
      console.log('postCompanies', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getDataVenueById = (id, token) => {
  return get(`${VENUES_ENDPOINT}/sumorders/${id}?app_id=${appId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const tempDataMap = {
        data: {
          data: [response.data.data]
        }
      };
      const result = utils.normalizeVenueById(tempDataMap);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getDataVenueById', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const fetchCompanies = (id, token, data) => {
  return patch(`${GET_COMPANIES_ENDPOINT}/${id}?app_id=${appId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    withCredentials: true,
    ...endpoints.setting
  })
    .then(response => {
      const tempDataMap = {
        data: {
          data: [
            {
              attributes: response.data.data
            }
          ]
        }
      };
      const result = utils.normalizeCompanies(tempDataMap);
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: result
      };
    })
    .catch(error => {
      console.log('fetchCompanies', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const patchVenue = (id, token, data) => {
  return patch(`${VENUES_ENDPOINT}/venue/${id}?app_id=${appId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    withCredentials: true,
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normailzeVenueList(response);
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: result
      };
    })
    .catch(error => {
      console.log('patchVenue', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const fetchOrders = (token, page, limit) => {
  return get(
    `${SUM_ORDERS_ENDPOINT}?app_id=molalivearena&page=${page}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...endpoints.setting
    }
  )
    .then(response => {
      const result = utils.normalizeOrder(response);
      return {
        data: result,
        hasNext: response.data.hasNext,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getSumOrder = token => {
  return get(`${VENUES_ENDPOINT}/sumorders?app_id=molalivearena`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeOrder(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      const errorMessage = _get(error, 'response.data.errors[0].detail', error)
      const responseStatus = _get(error, 'response.status', '');
      console.log('get sumorders', errorMessage);
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error: {
            message: errorMessage.toLowerCase().split('_').join(' ')
          }
        },
        data: []
      };
    });
};

const searchOrders = (
  token,
  keyword,
  startDate,
  endDate,
  companyID,
  cityID,
  limit,
  page
) => {
  const query = qs.stringify(
    {
      venue_keyword: keyword,
      start_date: startDate.replace(/\//g, '-'),
      end_date: endDate.replace(/\//g, '-'),
      company_id: companyID,
      city_id: cityID,
      page: page,
      limit: limit
    },
    { encode: false }
  );
  return get(`${VENUES_ENDPOINT}/sumorders?app_id=molalivearena&${query}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeOrder(response);
      return {
        data: result,
        hasNext: response.data.hasNext,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      const errorMessage = _get(error, 'response.data.errors[0].detail', error)
      const responseStatus = _get(error, 'response.status', '');
      console.log('get sumorders', errorMessage);
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error: {
            message: errorMessage.toLowerCase().split('_').join(' ')
          }
        },
        data: []
      };
    });
};

const getIsAdmin = token => {
  return get(`${GET_IS_ADMIN_ENDPOINT}s`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeAdmin(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const fetchLicenseChecker = (token, lic) => {
  return get(`${GET_LICENSE_CHECKER_ENDPOINT}/${lic}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeLicenseChecker(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const postPayment = (token, orderId) => {
  return patch(
    `${POST_DO_PAYMENT_ENDPOINT}/${orderId}?app_id=molalivearena`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...endpoints.setting
    }
  )
    .then(response => {
      const result = utils.normalizePaymentResponse(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('postPayment', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const postCertificate = (token, venueID) => {
  return post(
    `${POST_SEND_CERTIFICATE_ENDPOINT}?app_id=molalivearena`,
    {
      venueId: venueID
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...endpoints.setting
    }
  )
    .then(response => {
      // eslint-disable-next-line no-unused-vars
      const result = utils.normalizePaymentResponse(response);
      return {
        data: response,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('postCertificate', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const postToShow = (token, venueId, value) => {
  return patch(
    `${POST_SHOW_STATUS_ENDPOINT}/${venueId}?app_id=molalivearena`,
    {
      VenueShowStatus: value
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...endpoints.setting
    }
  )
    .then(response => {
      // eslint-disable-next-line no-unused-vars
      const result = utils.normalizePaymentResponse(response);
      return {
        data: response,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('postToShow', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const fetchDetailOrder = (orderId, token) => {
  return get(`${SUM_ORDERS_ENDPOINT}/${orderId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeDetailOrder(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('fetchDetailOrder', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getInvoice = (orderId, token) => {
  return get(`${SUM_ORDERS_ENDPOINT}/${orderId}`, null, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeOrder(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      if (error.response.status == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const postOrderReview = (token, data) => {
  return post(
    `${VENUES_ENDPOINT}/orders-by-agents?app_id=molalivearena`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
        //
      },
      withCredentials: true,
      ...endpoints.setting
    }
  )
    .then(response => {
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: response
      };
    })
    .catch(error => {
      console.log('postOrderReview', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const postOrderUser = (token, data) => {
  return post(`${VENUES_ENDPOINT}/orders?app_id=molalivearena`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
      //
    },
    withCredentials: true,
    ...endpoints.setting
  })
    .then(response => {
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: response
      };
    })
    .catch(error => {
      console.log('postOrderUser', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const venuePost = (token, data) => {
  return post(`${VENUES_ENDPOINT}/venue?app_id=molalivearena`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    withCredentials: true,
    ...endpoints.setting
  })
    .then(response => {
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: response
      };
    })
    .catch(error => {
      console.log('venuePost', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const fetchOrderReview = token => {
  return get(`${VENUE_ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normailzeVenueList(response);
      return {
        data: {
          venues: result
        },
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('fetchOrderReview', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getProductPrice = token => {
  return get(`${VENUES_ENDPOINT}/products?app_id=${appId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeProducts(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getProductPrice', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getDevice = (token, venType, capacity, aging) => {
  return get(
    `${VENUES_ENDPOINT}/order-matrix-devices/${venType}/${capacity}/${aging}?app_id=${appId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...endpoints.setting
    }
  )
    .then(response => {
      const result = utils.normalizeDevices(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getDevice', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};
const getInstallationPrice = token => {
  return get(`${VENUES_ENDPOINT}/installation?app_id=${appId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeInstallation(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getInstallationPrice', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getAging = (token, venType, capacity) => {
  return get(
    `${VENUES_ENDPOINT}/order-matrix-agings/${venType}/${capacity}?app_id=${appId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...endpoints.setting
    }
  )
    .then(response => {
      const result = utils.normalizeAging(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getAging', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getRoomPrice = token => {
  return get(`${VENUES_ENDPOINT}/rooms?app_id=${appId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeRoom(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      console.log('getRoomPrice', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const postCalculate = (token, data) => {
  return post(`${VENUES_ENDPOINT}/calculate-order?app_id=${appId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    withCredentials: true,
    ...endpoints.setting
  })
    .then(response => {
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: response.data.data
      };
    })
    .catch(error => {
      console.log('postCalculate', error);
      const responseStatus = _get(error, 'response.status', '');
      if (responseStatus && responseStatus == 401) {
        handleSessionOver();
      }
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getDataProvince = () => {
  return get(`${VENUES_ENDPOINT}/province?app_id=${appId}`, {
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeProvince(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getDataCityById = id => {
  return get(`${VENUES_ENDPOINT}/province/${id}?relationship=true`, {
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeCity(response.data.data.attributes.cities);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getCity = () => {
  return get(`${VENUES_ENDPOINT}/cities?app_id=molalivearena`, {
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeAllCity(response);
      return {
        data: result,
        meta: {
          status: 'success',
          error: ''
        }
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const fetchVenue = () => {
  return get(`${VENUES_ENDPOINT}/venues/available`, {
    ...endpoints.setting
  })
    .then(response => {
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: response.data
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const fetchRegional = () => {
  return get(`${VENUES_ENDPOINT}/regional_agents?app_id=molalivearena`, {
    ...endpoints.setting
  })
    .then(response => {
      return {
        meta: {
          status: 'success',
          error: ''
        },
        regional: response.data
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const deleteVenue = (token, id) => {
  return axiosDelete(`${VENUES_ENDPOINT}/venue/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {},
    ...endpoints.setting
  })
    .then(response => {
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: response
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const handleSearchVenue = query => {
  return get(
    `${VENUES_ENDPOINT}/search-venues-v2?${query}`,
    {
      ...endpoints.setting
    }
  )
    .then(response => {
      const result = utils.normallizeVenueV2(response);
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: result,
        hasNext: response.data.hasNext,
        totalPage: response.data.data.totalPage,
        fasilitiesVenue: response.data.data.DataResponse
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getSearchResult = ({ q }) => {
  return get(`${VENUES_ENDPOINT}/venue_suggestions/${q}?app_id=molalivearena`, {
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeSearchResult(response);
      return {
        meta: {
          status: result.length > 0 ? 'success' : 'no_result',
          error: ''
        },
        data: [...result] || []
      };
    })
    .catch(error => {
      const errorMessage = error.toString().replace('Error:', 'Search Result');
      return {
        meta: {
          status: 'error',
          error: errorMessage
        },
        data: []
      };
    });
};

const getFacilities = () => {
  return get(`${VENUES_ENDPOINT}/facilities?app_id=molalivearena`, {
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normalizeFacilities(response);
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: result
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getCapacity = () => {
  return get(`${VENUES_ENDPOINT}/capacities?app_id=molalivearena`, {
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normailzeCapacity(response);
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: result
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const getVenueType = () => {
  return get(`${VENUES_ENDPOINT}/venue_types?app_id=molalivearena`, {
    ...endpoints.setting
  })
    .then(response => {
      const result = utils.normailzeVenType(response);
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: result
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: []
      };
    });
};

const handleSessionOver = () => {
  let tempPath = history.location.pathname.split('/');
  if (tempPath[1] !== 'agents') {
    window.location.href = '/accounts/login';
  } else {
    window.location.href = '/agents/login';
  }
};

const getDetailVenueById = id => {
  return get(`${VENUES_ENDPOINT}/venue/${id}?app_id=molalivearena`, {
    ...endpoints.setting
  })
    .then(response => {
      return {
        meta: {
          status: 'success',
          error: ''
        },
        data: {
          ...response.data.data,
          ...response.data.data.attributes,
        },
      };
    })
    .catch(error => {
      return {
        meta: {
          status: 'error',
          error
        },
        data: {}
      };
    });
};

export default {
  getVenues,
  postOrderReview,
  postOrderUser,
  venuePost,
  fetchDetailOrder,
  getInvoice,
  fetchOrders,
  fetchOrderReview,
  getProductPrice,
  getDevice,
  getInstallationPrice,
  getAging,
  getRoomPrice,
  postPayment,
  fetchLicenseChecker,
  postCertificate,
  getIsAdmin,
  postCalculate,
  getDataProvince,
  getDataCityById,
  fetchVenue,
  postToShow,
  getDataVenueById,
  patchVenue,
  searchOrders,
  getCapacities,
  getCity,
  getSumOrder,

  // company endpoints
  getDataCompanies,
  getDataCompaniesById,
  postCompanies,
  fetchCompanies,
  fetchRegional,
  deleteVenue,
  handleSearchVenue,
  getDetailVenueById,

  // search landingpage endpoint
  getSearchResult,
  getFacilities,
  getCapacity,
  getVenueType
};
