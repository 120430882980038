import styled, { css } from 'react-emotion';
import theme from '@source/components/Layout/theme';
import { icons } from '../../../../components/Layout/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: var(--fullvh, 100vh);
  background: #0C1824;
  .background-cover {
    object-fit: cover;
    object-position: top;
    width: 100vw;
    height: var(--fullvh, 100vh);
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }
  .background-overlay {
    width: 100vw;
    height: var(--fullvh, 100vh);
    background-color: #0C1824;
    opacity: 0.85;
    position: absolute;
    top: 0;
    left: 0;
  }
  > * {
    /* position: relative; */
  }
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme === 'light' ? '1rem' : '1.6rem'};;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  background: ${(props) => props.theme === 'light' ? 'white' : 'transparent'};
`;

const WrapHeader = styled.div`
  width: ${({ layout }) => layout === 'fixed' ? theme.fixedWidth.xs : '100%'};
  margin: 0 auto;
  @media screen and (min-width: ${theme.breakpoint.s}) {
    width: ${({ layout }) => layout === 'fixed' ? theme.fixedWidth.xs : '100%'};
  }
  @media screen and (min-width: ${theme.breakpoint.normal}) {
    width: ${({ layout }) => layout === 'fixed' ? theme.fixedWidth.s : '100%'};
  }
  @media screen and (min-width: ${theme.breakpoint.l}) {
    width: ${({ layout }) => layout === 'fixed' ? theme.fixedWidth.normal : '100%'};
  }
`;

const DesktopMenu = styled.div`
  display: none;
  margin: auto;
  @media screen and (min-width: ${theme.breakpoint.s}) {
    display: flex;
  }
`;

const CrossBlackButton = styled.button`
  cursor: pointer;
  border: 0;
  background-color: transparent;
  outline: none;
  position: absolute;
  top: 1.8rem;
  left: 1.8rem;
  background-image: url(${icons.crossBlack});
  background-repeat: no-repeat;
  width: 1.9rem;
  height: 1.9rem;
`;

const WrapDrawer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: var(--fullvh, 100vh);
  display: block;
  z-index: 22;
  @media screen and (min-width: ${theme.breakpoint.s}) {
    display: none;
  }
`;

const DrawerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.state === 'active' ? '0' : '-100vw'};
  width: 100vw;
  height: var(--fullvh, 100vh);
  background: rgba(0, 0, 0, 0.6);
`;

const DrawerBox = styled.div`
  position: fixed;
  top: 0;
  left: ${props => props.state === 'active' ? '0' : '-25rem'};
  width: 25rem;
  height: var(--fullvh, 100vh);
  background: white;
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: ${props => props.state === 'active' ? '0 0.4rem 1rem 1.5rem rgba(0, 0, 0, 0.1)' : 'none'};
`;

const DrawerBoxHead = styled.div`
  height: 14rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FAFAFA;
  margin-bottom: 4rem;
  .drawer-logo {
    height: 5.5rem;
  }
`;

const DrawerBoxFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  strong, p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }
  p {
    color: #707070;
  }
  @media screen and (min-width: ${theme.breakpoint.s}) {
    padding: 1.4rem;
  }
`;

const Menu = styled.ul`
  margin: 0;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Open Sans';
  @media screen and (min-width: ${theme.breakpoint.s}) {
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
`;

const activeMenu = css`
  font-weight: 700;
  &:after {
    width: 80%;
  }
  @media screen and (min-width: ${theme.breakpoint.s}) {
    font-weight: 400;
    &:after {
      width: 30%;
    }
  }
`;

const MenuItem = styled.li`
  margin: 0;
  padding: 1rem 0;
  list-style: none;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
  font-weight: 400;
  &:after {
    content: '';
    bottom: 0;
    position: absolute;
    left: 0;
    width: 0rem;
    height: 0.2rem;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background-color: ${theme.colors.primary};
  }
  &:hover {
    &:after {
      width: 80%;
    }
    @media screen and (min-width: ${theme.breakpoint.s}) {
      font-weight: 400;
      &:after {
        width: 30%;
      }
    }
  }
  ${props => props.state === 'active' && activeMenu}
  @media screen and (min-width: ${theme.breakpoint.s}) {
    font-size: 1.8rem;
    padding: 1.5rem 2rem;
    color: ${(props) => props.theme === 'light' ? '#000000' : 'white'};
    &:after {
      left: calc(50%);
      height: 0.4rem;
      bottom: 0.3rem;
      
      transform: translate(-50%, 0);
    }
  }
`;

const PrimarySection = styled.div`
  display: flex;
  align-items: center;
`;

const hamburgerIcon = {
  light: css`
    background-image: url(${icons.hamburgerWhite});
  `,
  dark: css`
    background-image: url(${icons.hamburgerBlack});
  `,
}

const HamburgerMenu = styled.button`
  cursor: pointer;
  border: 0;
  outline: none;
  width: 2.2rem;
  height: 2.2rem;
  background-color: transparent;
  background-repeat: no-repeat;
  ${props => hamburgerIcon[props.color]};
  background-size: 100%;
  background-position: center;
  padding: 0.25rem;
  margin-left: 1rem;
  @media screen and (min-width: ${theme.breakpoint.s}) {
    display: none;
  }
`;

const HeaderLogo = styled.a`
  height: 3.6rem;
  margin-left: 1.7rem;
  display: flex;
  @media screen and (min-width: ${theme.breakpoint.s}) {
    height: 5.2rem;
    margin-left: 0;
    margin-right: -52px;
  }
  img {
    height: 100%;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 1.6rem;
  .main-logo {
    width: 20rem;
    flex-shrink: 0;
    @media screen and (min-width: ${theme.breakpoint.s}) {
      width: 36rem;
    }
  }
`;

const Footer = styled.footer`
  color: ${(props) => props.theme === 'light' ? '#A2A2A2' : 'white'};
  font-size: 1rem;
  font-weight: 100;
  font-family: 'Public Sans';
  line-height: 1.3;
  text-align: center;
  padding: 2.4rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${(props) => props.bg ? props.bg : '#FAFAFA'};
  position: relative;
  p {
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: ${theme.breakpoint.s}) {
    display: flex;
    font-size: 1.6rem;
  }
`;

const MainSpacing = styled.div`
  height: 60px;
  width: 100%;
  display: block;
  transition: 0.2s height;
`;

export {
  Container,
  Header,
  WrapHeader,
  HeaderLogo,
  DesktopMenu,
  PrimarySection,
  HamburgerMenu,
  Main,
  MainSpacing,
  Footer,
  CrossBlackButton,
  WrapDrawer,
  DrawerOverlay,
  DrawerBox,
  DrawerBoxHead,
  DrawerBoxFooter,
  Menu,
  MenuItem,
}