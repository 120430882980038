// ONLY RETURN TRUE OR FALSE

const isUndefinedNull = variable => variable === null || variable === undefined;

const isString = variable => typeof variable === 'string' || variable instanceof String;

const isObject = variable => (!!variable) && (variable.constructor === Object);

function isURL(str) {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

const isEmpty = (any) => {
  if (
    any === undefined
    || any === null
    || (Object.entries(any).length === 0 && typeof any !== 'number')
  ) {
    return true;
  }
  return false;
};

const validURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

const isUUID = (str) => {
  const uuidPattern = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
  return uuidPattern.test(str);
};


export {
  isUndefinedNull,
  isString,
  isObject,
  isURL,
  isEmpty,
  validURL,
  isUUID,
};
