import React from 'react';
import {
  WrapDrawer,
  DrawerOverlay,
  DrawerBox,
  DrawerBoxHead,
  CrossBlackButton,
  DrawerBoxFooter,
} from '../styles';

import { logoMLA } from '@source/global/imageUrl';

import Menus from './Menu';

const Drawer = ({ open, onClose, pathname }) => {
  return (
    <WrapDrawer>
      <DrawerOverlay onClick={() => onClose()}  state={open ? 'active' : ''}/>
      <DrawerBox state={open ? 'active' : ''}>
        <section>
          <DrawerBoxHead>
            <CrossBlackButton onClick={() => onClose()} />
            <img className="drawer-logo" src={logoMLA} alt="molalivearena logo" />
          </DrawerBoxHead>
          <Menus pathname={pathname} />
        </section>
        <DrawerBoxFooter>
          <strong>Mola TV Live Arena © {new Date().getFullYear()}</strong>
          <p>Hak cipta, siar, dan publikasi Mola TV Live Arena telah dilindungi oleh undang-undang.</p>
        </DrawerBoxFooter>
      </DrawerBox>
    </WrapDrawer>
  );
};

export default Drawer
