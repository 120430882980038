import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './loading.css';
import { connect } from 'react-redux';
import { compose } from 'redux';

class Loading extends React.Component {
  state = {
    handleLoading: false
  };

  componentDidUpdate(prevProps) {
    const { loadingComponent } = this.props;
    if (
      loadingComponent.meta.status != prevProps.loadingComponent.meta.status &&
      loadingComponent.meta.status === 'loading'
    ) {
      this.setState({
        handleLoading: true
      });
    } else if (
      loadingComponent.meta.status != prevProps.loadingComponent.meta.status &&
      (loadingComponent.meta.status === 'success' ||
        loadingComponent.meta.status === 'error')
    ) {
      this.setState({
        handleLoading: false
      });
    }
    // if ( loadingComponent.meta.status !== 'loading') {
    //   this.setState({
    //     handleLoading: false
    //   })
    // }else {
    //   this.setState({
    //     handleLoading: true
    //   })
    // }
  }

  render() {
    const { handleLoading } = this.state;
    return (
      <React.Fragment>
        {handleLoading && (
          <div>
            <div className={`ui active page dimmer`}>
              <div className={`ui text loader`}>Loading</div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Loading);
