import { getCookie, setCookie } from "./util";
import { isEmpty } from "./variables";

const USER_COORDINATE = 'uc';

/**
 * can be null
 */
export const getUserCoordinate = () => {
  const uc = getCookie(USER_COORDINATE)
  if (!isEmpty(uc)) {
    return { lat: uc.split(',')[0], lng: uc.split(',')[1] }
  }
  return null
}

/**
 * set user lat lng from device
 * @param {float} lat latitude
 * @param {float} lng longitude
 */
export const setUserCoordinate = (lat, lng) => {
  if (isEmpty(lat) || isEmpty(lng)) {
    console.warn('cant\'t set coordinate');
  }
  setCookie(USER_COORDINATE, `${lat}, ${lng}`, 90)
}
