const normailzeVenueList = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { venueTypeID, venueTypeName } } = result;
      return {
        id,
        type,
        attributes: {
          venueTypeID,
          venueTypeName
        }
      };
    });
  }
  return [];
};

const normailzeCapacities = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { capacityID, capacityName } } = result;
      return {
        id,
        type,
        attributes: {
          capacityID,
          capacityName
        }
      };
    });
  }
  return [];
};

const normalizeOrder = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      return {
        ...result,
        ...result.attributes,
        id: result.id,
      };
    });
  }
  return [];
};

const normalizeSearchOrder = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      return {
        ...result.attributes,
        id: result.id,
      };
    });
  }
  return [];
};

const normalizePaymentResponse = response => {
  const { data } = response.data;
  return {
    data
  };
};

const normalizeLicenseChecker = response => {
  const { data } = response.data;
  return {
    data
  };
};

const normalizeDevices = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { deviceID, deviceName } } = result;
      return {
        id,
        type,
        deviceID,
        deviceName
      };
    });
  }
  return [];
};

const normalizeDetailOrder = response => {
  const { data } = response.data;
  return {
    data
  };
};

const normalizeAdmin = response => {
  const { data } = response.data;
  return {
    data
  };
};

const normalizeInstallation = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { description, price, name } } = result;
      return {
        id,
        type,
        description,
        name,
        price
      };
    });
  }
  return [];
};

const normalizeAging = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { agingID, agingName } } = result;
      return {
        id,
        type,
        agingID,
        agingName
      };
    });
  }
  return [];
};

const normalizeProducts = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const {
        id,
        type,
        attributes: { description, productName, price }
      } = result;
      return {
        id,
        type,
        description,
        productName,
        price
      };
    });
  }
  return [];
};

const normalizeRoom = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { description, name, price } } = result;
      return {
        id,
        type,
        description,
        name,
        price
      };
    });
  }
  return [];
};

const normalizeCompanies = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const {
        attributes: { address, city, email, id, name, npwp, province, provinceID, cityID, zip, ID }
      } = result;
      return {
        address,
        city,
        cityID,
        provinceID,
        email,
        id,
        name,
        npwp,
        province,
        zip,
        ID
      };
    });
  }
  return [];
};

const normalizeProvince = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const {
        id,
        type,
        attributes: { province_id, province, country_id }
      } = result;
      return {
        id,
        type,
        province_id,
        province,
        country_id
      };
    });
  }
  return [];
};

const normalizeCity = response => {
  const data = response;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { province_id, city } } = result;
      return {
        id,
        type,
        province_id,
        city
      };
    });
  }
  return [];
};
const normalizeAllCity = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { province_id, city } } = result;
      return {
        id,
        type,
        province_id,
        city
      };
    });
  }
  return [];
};

const normallizeVenueV2 = response => {
  const  DataResponse  = response.data.data;
  if (DataResponse && DataResponse.length > 0) {
    return DataResponse.map(result => {
      return { ...result.attributes, id: result.id };
    });
  }
  return [];
};

const normalizeVenueById = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      return {
        ...result,
        ...result.attributes,
        id: result.id
      };
    });
  }
  return [];
};

const normalizeSearchResult = response => {
  const { data } = response.data
  if (data && data.length > 0) {
    return data.map(result => {
      const {
        id,
        type,
        attributes: {
          key
        },
      } = result

      if (type == 'city') {
        return {
          id,
          type,
          key,
        }
      } else {
        return {
          id,
          type,
          key
        }
      }
    })
  }
  return []
}

const normalizeFacilities = response => {
  const { data } = response.data
  if (data && data.length > 0) {
    return data.map(result => {
      const {
        id,
        type,
        attributes: {
          name
        },
      } = result
      return {
        id,
        type,
        name,
        value: name
      }
    })
  }
  return []
}


const normailzeCapacity = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { name } } = result;
      return {
        id,
        type,
        name,
        value: name
      };
    });
  }
  return [];
};

const normailzeVenType = response => {
  const { data } = response.data;
  if (data && data.length > 0) {
    return data.map(result => {
      const { id, type, attributes: { name } } = result;
      return {
        id,
        type,
        name,
        value: name
      };
    });
  }
  return [];
};

const normallizeVenueDetail = response => {
  const { data } = response.data;
  if (data && data.length) {
    return data.map(result => {
      return {
        ...result,
        ...result.attributes,
        id: result.id,
      };
    });
  }
  return [];
};


export default {
  normailzeVenueList,
  normalizeOrder,
  normalizeSearchOrder,
  normalizeDetailOrder,
  normalizeProducts,
  normalizeDevices,
  normalizeInstallation,
  normalizeAging,
  normalizeRoom,
  normalizePaymentResponse,
  normalizeLicenseChecker,
  normalizeAdmin,
  normalizeCompanies,
  normalizeProvince,
  normalizeCity,
  normalizeVenueById,
  normailzeCapacities,
  normallizeVenueV2,
  normalizeAllCity,
  normalizeSearchResult,
  normallizeVenueDetail,
  normalizeFacilities,
  normailzeCapacity,
  normailzeVenType
};
