import styled, { keyframes } from 'react-emotion';
import theme from '@source/components/Layout/theme';
import { icons } from '../../../components/Layout/theme';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 1.6rem;
  flex-grow: 1;
  justify-content: flex-end;
  .main-logo {
    width: 20rem;
    flex-shrink: 0;
    position: relative;
    @media screen and (min-width: ${theme.breakpoint.s}) {
      width: 36rem;
    }
  }
`;

const Footer = styled.footer`
  color: white;
  font-size: 1rem;
  font-weight: 100;
  font-family: 'Public Sans';
  line-height: 1.3;
  display: block;
  text-align: center;
  padding: 1.6rem;
  p {
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: ${theme.breakpoint.s}) {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
`;

const WrapMainSearch = styled.div`
  margin-top: 10rem;
  width: 100%;
`;

const BigInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: transparent;
  outline: 0;
  border: 0;
  position: relative;
  ::placeholder {
    font-family: 'Open Sans';
    color: ${({ state }) => state === 'active' ? '#ACACAC' : 'transparent'};
  }
  @media screen and (min-width: ${theme.breakpoint.s}) {
    padding: 1rem 8rem 1rem 1rem;
  }
`;

const WrapPlaceHolderInput = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ state }) => state === 'active' ? 'flex' : 'none'};
  justify-content: flex-start;
  @media screen and (min-width: ${theme.breakpoint.s}) {
    padding: 1.8rem;
  }
`;

const PlaceHolderInput = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  p {
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: 1.3rem;
    @media screen and (min-width: ${theme.breakpoint.s}) {
      font-size: 1.6rem;
    }
  }
  strong, b {
    font-weight: 600;
  }
  strong {
    color: ${theme.colors.primary};
  }
`;

const SuggestionListStyled = styled.ul`
  padding: 0px;
  margin: 0rem 0 1rem;
  list-style: none;
  overflow-y: auto;
  display: ${({ state }) => state === 'active' ? 'block' : 'none' };
  :before {
    content: '';
    height: 0.1rem;
    width: 90%;
    margin: 0 auto 0.7rem;
    background: #EBEDF0;
    display: block;
  }
`;

const SuggestionListItem = styled.li`
  cursor: pointer;
  padding: 1rem 2rem 1rem 4.4rem;
  margin: 0px;
  &:hover {
    background: rgba(0,0,0,0.2);
  }
`;

const SuggesstionItemHistory = styled(SuggestionListItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 2rem 1rem;
  :before {
    margin-right: 1rem;
    background-image: url(${icons.historyBlack});
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    width: 1.4rem;
    height: 1.4rem;
  }
`;

const SearchButton = styled.button`
  cursor: pointer;
  outline: none;
  border: 0;
  width: 4rem;
  height: 100%;
  border-radius: 0 0.4rem 0.4rem 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ state }) => state === 'active' ? '#F2F2F2' : 'white'};
  background-image: url(${icons.searchBlack});
  background-repeat: no-repeat;
  background-size: 1.4rem 1.4rem;
  background-position: center;
  @media screen and (min-width: ${theme.breakpoint.s}) {
    background-size: 2.4rem;
    width: 7rem;
  }
`;

const MaskInput = styled.div`
  position: relative;
  height: 4.1rem;
  background-color: ${({ state }) => state === 'active' ? '#F2F2F2' : 'white'};
  border-radius: ${({ state }) => state === 'active' ? '0.4rem 0.4rem 0 0' : '0.4rem'};
  @media screen and (min-width: ${theme.breakpoint.s}) {
    height: 6rem;
    width: 63.2rem;
    margin: 0 auto;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 4rem;
  background: white;
  max-height: 50vh;
  width: 100%;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 0;
  display: flex;
  display: ${({ state }) => state === 'active' ? 'flex' : 'none'};
  flex-direction: column;
  @media screen and (min-width: ${theme.breakpoint.s}) {
    top: 6rem;
  }
`;

const GetNear = styled.button`
  color: #717171;
  background: transparent;
  cursor: pointer;
  border: 0;
  outline: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1.4rem;
  height: 4rem;
  padding: 1rem 2rem;
  &:hover {
    background: rgba(0,0,0,0.2);
  }
  :before {
    margin-right: 1rem;
    background-image: url(${icons.locationBlack});
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    content: "";
    width: 1.2rem;
    height: 1.6rem;
    padding: 0.2rem;
  }
`;

const MainSpacing = styled.div`
  height: 50px;
  width: 100%;
  display: block;
  transition: 0.2s height;
`;

const visible = keyframes`
  to {
    visibility: visible;
    z-index: 2;
  }
`
const PopupOverlay = styled.div`
  animation: ${visible} 0s 1s forwards;
  visibility: hidden;
  display: flex;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
`;

const Popup = styled.div`
  position: relative;
  height: calc(100vw - 30px);
  width: calc((100vw - 30px) * 0.8);
  background: white;
  @media screen and (min-width: ${theme.breakpoint.xs}) {
    height: calc(100vh - 275px);
    width: calc((100vh - 275px) * 0.8);
  }
`;

const PopupSlide = styled.div`
  height: calc(100vw - 30px);
  width: calc((100vw - 30px) * 0.8);
  @media screen and (min-width: ${theme.breakpoint.xs}) {
    height: calc(100vh - 275px);
    width: calc((100vh - 275px) * 0.8);
  }
`;

const PopupSlideImg = styled.img`
  height: calc(100vw - 30px);
  width: calc((100vw - 30px) * 0.8);
  @media screen and (min-width: ${theme.breakpoint.xs}) {
    height: calc(100vh - 275px);
    width: calc((100vh - 275px) * 0.8);
  }
`;

const PopupClose = styled.button`
  position: absolute;
  z-index: 3;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 0;
  background: #49A5CE;
  color: #074E7C;
  display: flex;
  justify-content: center;
  font-size: 30px;
  line-height: 27px;
  &:hover {
    color: #fff;
  }
`;

export {
  Main,
  WrapMainSearch,
  WrapPlaceHolderInput,
  PlaceHolderInput,
  MaskInput,
  Dropdown,
  BigInput,
  GetNear,
  MainSpacing,
  Footer,
  SearchButton,
  SuggestionListStyled,
  SuggestionListItem,
  SuggesstionItemHistory,
  PopupOverlay,
  Popup,
  PopupSlide,
  PopupSlideImg,
  PopupClose,
}