import Dexie from 'dexie';

let db = null
if (navigator.cookieEnabled) {
  db = new Dexie('mla-search-cache-database')
  db.version(1).stores({ searchKeyword: '++id, keyword, createdDate' })
  db.version(1).stores({ venuesResult: '++id, venueId, createdDate' })
  db.version(1).stores({ citiesResult: '++id, cityId, createdDate' })
  db.version(1).stores({ recentResult: '++id, recent, createdDate' })
  db.open().catch(function(err) {
    // console.log(err.stack || err)
  })
}

export default db;
