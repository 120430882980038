import React, { useState, useCallback, useMemo } from 'react';
import history from '@source/history';
import searchActions from '@actions/search';
import debounce from '@source/lib/debounce';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { saveRecentSearch, getRecentSearch } from '../shared';

import carousel from 'react-responsive-carousel/lib/styles/carousel.css';
import { Carousel } from 'react-responsive-carousel';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import {
  imgPopup1,
  imgPopup2,
} from '@source/global/imageUrl';

import {
  Main,
  WrapMainSearch,
  BigInput,
  MaskInput,
  WrapPlaceHolderInput,
  PlaceHolderInput,
  Dropdown,
  GetNear,
  MainSpacing,
  SearchButton,
  SuggestionListStyled,
  SuggestionListItem,
  SuggesstionItemHistory,
  PopupOverlay,
  Popup,
  PopupSlide,
  PopupSlideImg,
  PopupClose,
} from './HomeStyle';

import { backgroundHome as backgroundHomeDefault, logoMLA } from '@source/global/imageUrl';
import LayoutPublic, { Footer } from '../shared/components/LayoutPublic';
import { setUserCoordinate } from '../../../lib/cookies';

const Home = ({ pathname, isMobile, configParams }) => {
  const [spaceBottomSearch, setSpaceBottomSearch] = useState(null)

  const handleFocusSearch = () => {
    setSpaceBottomSearch('40vh')
  }

  const handleSearchBlur = () => {
    setSpaceBottomSearch(null)
  }

  const backgroundHome = _get(configParams, 'homeBackground.background', backgroundHomeDefault)

  return (
    <LayoutPublic
      layout="fluid"
      bgFooter="transparent"
      isMobile={isMobile}
      footerTheme="dark"
      pathname={pathname}>
      <WelcomePopup />
      <img className="background-cover" alt="molalivearena background crowd" src={backgroundHome} />
      {/* <div className="background-overlay" /> TODO: overlay configure from alrez> */}
      <Main>
        {/* <img className="main-logo" src={logoMLA} alt="molalivearena logo" /> */}
        <MainSearch onFocus={handleFocusSearch} onBlur={handleSearchBlur} />
        <MainSpacing style={{ height: spaceBottomSearch }} />
      </Main>
      {isMobile && <Footer bg="transparent" theme="dark" />}
    </LayoutPublic>
  );
};

const Search = ({ getSuggestion, search, onFocus, onBlur }) => {
  const [focus, setFocus] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const debouncedSearch = useCallback(debounce(getSuggestion, 800), []);

  const handleChangeSearch = e => {
    if(e.target.value !== '') {
      debouncedSearch(e.target.value)
    }
    setSearchText(e.target.value)
  }

  const handleFocus = () => {
    setFocus(true)
    if (typeof onFocus === 'function') onFocus()
  }

  const handleBlur = () => {
    setTimeout(() => {
      if (typeof onBlur === 'function') onBlur()
      setFocus(false)
    }, 300)
  }

  const gotoSearch = text => {
    if (text === '' || !text) return
    history.push(`/lokasi?keyword=${text}`);
  }

  const handleClickSuggestion = item => {
    handleBlur()
    switch (item.type) {
    case 'venue_name':
      history.push(`/venue/${item.key}/${item.id}`);
      return
    case 'city':
      gotoSearch(item.key)
      return
    default:
      gotoSearch(searchText)
      return
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      saveRecentSearch({ key: searchText, id: searchText, type: 'text' })
      gotoSearch(searchText);
    }
  }

  const isEmpty = useMemo(() => (searchText === '' || !searchText), [searchText])

  return (
    <WrapMainSearch>
      <MaskInput state={focus ? 'active' : ''}>
        <WrapPlaceHolderInput state={isEmpty ? !focus && 'active' : ''}>
          <PlaceHolderInput>
            <p>Cari <strong>Tempat Nonton Bareng Mola</strong> <b>Terdekat</b></p>
          </PlaceHolderInput>
        </WrapPlaceHolderInput>
        <BigInput
          placeholder="Nama Caffe atau Kota..."
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyPress}
          onChange={handleChangeSearch}
          state={focus ? 'active' : ''}
        />
        <SearchButton
          onClick={() => gotoSearch(searchText)}
          state={focus ? 'active' : ''}
        />
        <Dropdown state={focus ? 'active' : ''}>
          <GetNearSuggestion />
          <SuggestionList
            opened={focus}
            keyword={searchText}
            status={search.meta.status}
            list={search.data || []}
            onClickItem={handleClickSuggestion}
          />
        </Dropdown>
      </MaskInput>
    </WrapMainSearch>
  );
}

const SuggestionList = ({ list, onClickItem, opened, keyword }) => {
  const handleClick = item => {
    saveRecentSearch(item)
    if (typeof onClickItem === 'function') onClickItem(item)
  }

  const recentSearch = useMemo(() => {
    if (!opened) return
    return getRecentSearch()
  }, [opened])

  const recentMatch = useMemo(() => {
    if (recentSearch && keyword) {
      const lowKeyword = keyword.toLowerCase()
      const reg = new RegExp(lowKeyword)
      return reg.test(recentSearch.key.toLowerCase()) ? recentSearch : null
    }
    return null
  }, [recentSearch, keyword])

  const listSuggestion = list.map(suggestion => ({ ...suggestion, ...suggestion.attributes }))

  return (
    <SuggestionListStyled state={recentMatch || listSuggestion.length ? 'active' : null} >
      <HistoryItem recentSearch={recentMatch} onClick={(item) => handleClick(item)} />
      {listSuggestion.map(item => (
        <SuggestionListItem
          key={item.id + item.city}
          onClick={() => handleClick(item)}
        >{item.key}</SuggestionListItem>
      ))}
    </SuggestionListStyled>
  )
}

const HistoryItem = ({ onClick, recentSearch }) => {
  const handleClick = item => {
    if (typeof onClick === 'function') onClick(item)
  }

  if(!recentSearch) {
    return <></>
  }

  return (
    <SuggesstionItemHistory onClick={() => handleClick(recentSearch)}>
      {recentSearch.key}
    </SuggesstionItemHistory>
  )
}

const onHasLocation = ({ coords }) => {
  setUserCoordinate(coords.latitude, coords.longitude)
  history.push(`/lokasi?sort=2&lat=${coords.latitude}&lng=${coords.longitude}`);
}

const GetNearSuggestion = () => {
  const handleClickLocation = () => {
    if (window.navigator) {
      navigator.geolocation.getCurrentPosition(onHasLocation,
        () => alert('Turn on/Allow location'),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 1,
        },
      );
    }
  }

  return (
    <GetNear onClick={handleClickLocation}>
      Lokasi anda saat ini
    </GetNear>
  )
}


const WelcomePopup = () => {
  const closePopup = () => {
    const popupEl = document.querySelector('#popup');

    if (popupEl) {
      popupEl.style.display = 'none'
    }

    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('hidePopup', true)
    }
  }

  let hidePopup = false

  if (typeof window !== 'undefined') {
    hidePopup = window.sessionStorage.getItem('hidePopup')
  }

  return !hidePopup && (
    <PopupOverlay id="popup">
      <Popup>
        <PopupClose type="button" onClick={closePopup}>&times;</PopupClose>
        <Carousel
          infiniteLoop={true}
          dynamicHeight={true}
          useKeyboardArrows={true}
          swipeable={true}
          stopOnHover={true}
          showThumbs={false}
          showIndicators={true}
          showStatus={false}
        >
          <PopupSlide>
            <PopupSlideImg src={imgPopup1} />
          </PopupSlide>
          <PopupSlide>
            <PopupSlideImg src={imgPopup2} />
          </PopupSlide>
        </Carousel>
      </Popup>
    </PopupOverlay>
  )
}

const mapStateToPropsHome = ({ configParams }) => ({ configParams });
const mapStateToPropsMainSearch = ({ search }) => ({ search });

const mapDispatchToProps = dispatch => ({
  getSuggestion: searchText => dispatch(searchActions.getSearchSuggestion(searchText)),
});

const MainSearch = connect(mapStateToPropsMainSearch, mapDispatchToProps)(Search);

export default connect(mapStateToPropsHome, null)(withStyles(carousel)(Home));
