import { injectGlobal } from 'emotion'
import { semanticBrandIcons, semanticIcons, semanticOutlineIcons } from '../../global/imageUrl'

// semanticOutlineIcons
injectGlobal`
  /*******************************
  Semantic UI Icon
  *******************************/
  @font-face {
    font-family: 'Icons';
    src: url(${`${semanticIcons}.eot`});
    src: url(${`${semanticIcons}.eot?#iefix`})
      format('embedded-opentype'),
      url(${`${semanticIcons}.woff2`})
      format('woff2'),
      url(${`${semanticIcons}.woff`})
      format('woff'),
      url(${`${semanticIcons}.ttf`})
      format('truetype'),
      url(${`${semanticIcons}.svg#icons`})
      format('svg');
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-transform: none;
  }
  /*******************************
  Semantic UI Outline Icons
  *******************************/
  @font-face {
    font-family: 'outline-icons';
    src: url(${`${semanticOutlineIcons}.eot`});
    src: url(${`${semanticOutlineIcons}.eot?#iefix`})
      format('embedded-opentype'),
      url(${`${semanticOutlineIcons}.woff2`})
      format('woff2'),
      url(${`${semanticOutlineIcons}.woff`})
      format('woff'),
      url(${`${semanticOutlineIcons}.ttf`})
      format('truetype'),
      url(${`${semanticOutlineIcons}.svg#icons`})
      format('svg');
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-transform: none;
  }
  /*******************************
  Semantic UI Brand Icons
  *******************************/
  @font-face {
    font-family: 'brand-icons';
    src: url(${`${semanticBrandIcons}.eot`});
    src: url(${`${semanticBrandIcons}.eot?#iefix`})
      format('embedded-opentype'),
      url(${`${semanticBrandIcons}.woff2`})
      format('woff2'),
      url(${`${semanticBrandIcons}.woff`})
      format('woff'),
      url(${`${semanticBrandIcons}.ttf`})
      format('truetype'),
      url(${`${semanticBrandIcons}.svg#icons`})
      format('svg');
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-transform: none;
  }
`