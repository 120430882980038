import { addWeeks, isAfter } from 'date-fns';
const LS_KEY_RECENT_SEARCH = 'mla-recent-search';

const saveRecentSearch = (searchItem) => {
  return localStorage.setItem(LS_KEY_RECENT_SEARCH, JSON.stringify({ ...searchItem, created_at: +new Date() }))
}

const getRecentSearch = () => {
  let item = localStorage.getItem(LS_KEY_RECENT_SEARCH)
  if (!item) return null
  item = JSON.parse(item)
  if (isAfter(new Date(), addWeeks(item.created_at, 1))) {
    localStorage.removeItem(LS_KEY_RECENT_SEARCH)
    return null
  }
  return item
}

export {
  saveRecentSearch,
  getRecentSearch,
}