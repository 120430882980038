import React, { useEffect, useState } from 'react';
import history from '@source/history';
import { connect } from 'react-redux';
import {
  Menu,
  MenuItem,
} from '../styles';

const Menus = ({ onMenuClick, pathname, theme, configParams }) => {
  const menus = configParams.menus || []
  const [menusActive, setMenusActive] = useState([])

  useEffect(() => {
    setMenusActive(menus.filter(m => m.active))
  }, [menus])

  const handleClickMenu = menu => {
    if (typeof onMenuClick === 'function' ) onMenuClick(menu)
    if (menu.outbound) {
      window.open(menu.link, '_blank')
      return;
    }
    history.push({ pathname: menu.link, state: {} });
  }

  return (
    <Menu>
      {menusActive.map(menu => {
        const alias = menu.alias ? new RegExp(menu.alias) : /\^somerandomneverbehere$/
        return (
          <MenuItem key={menu.label}
            state={(pathname === menu.link || alias.test(pathname)) ? 'active' : ''}
            onClick={() => handleClickMenu(menu)}
            theme={theme}
          >
            {menu.label}
          </MenuItem>
        )
      })}
    </Menu>
  )
}

const mapStateToProps = ({ configParams }) => ({ configParams })
export default connect(mapStateToProps)(Menus)
