import React from 'react'
import Layout from '@components/Layout';
import Home from './Home';

function action({ pathname, isMobile }) {
  return {
    title: 'Beranda',
    chunks: ['home'],
    component: (
      <Layout>
        <Home isMobile={isMobile} pathname={pathname} />
      </Layout>
    ),
  }
}

export default action